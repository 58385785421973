body {
  margin: 0;
  background-color: #f5f5f5; /* leichter grauer Hintergrund für die ganze Seite */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  background-color: #ffffff; /* weißer Hintergrund für Karten */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* leichter Schattenwurf für Karten */
  padding: 20px;
  margin-bottom: 20px; /* Abstand zwischen den Karten */
}

